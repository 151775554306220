html,
body {
  height: 100vh;
  margin: 0;
}
:root {
  --primary: #011f35;
}
.content {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
@media only screen and (max-width: 700px) {
  .content {
    flex-direction: column;
  }
  .info {
    flex: 1;
    min-width: 100%;
  }
}

.info {
  width: 400px;
  overflow: scroll;
}
.map {
  flex: 1;
}

#qruta {
  background-color: var(--primary);
  padding: 10px;
}
#qruta img {
  max-height: 30px;
}
