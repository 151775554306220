.empty {
  height: 100vh;
  position: relative;
}
.items {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 6rem;
  text-align: center;
  color: var(--primary);
}
