.actionbar {
  padding: 10px 15px;
  background-color: var(--primary);
  color: white;
  font-size: 1.5rem;
  max-width: 100%;
}
.actionbar .icon {
  margin-right: 10px;
}
.actionbar .title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
